<template>
  <create-item-component
      endpoint="problems"
      type="problem"/>
</template>
<script>

export default {
  name: "Problems"
}
</script>